import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'
import { useState } from 'react'

const lightboxOptions = {
  settings: {
    overlayColor: 'rgb(0,0,0,0.8)',
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: 'rgba(0,0,0,0)',
    iconColor: '#fff',

    iconPadding: '10px',
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
    size: '40px',
  },
  caption: {
    captionColor: '#fff',
    captionFontFamily: 'EMT-Aribau-Grotesk',
    captionFontWeight: '300',
  },
  thumbnails: {
    thumbnailsGap: '0 0.5em',
    thumbnailsOpacity: 0.7,
  },
}

const PhotoGalleryPage = ({ data, location }) => {
  console.log(data)
  const [maxImages, setMaxImages] = useState(6)
  const callbacks = {
    onLightboxOpened: object => {
      setMaxImages(100)
    },
  }
  const page = data.prismicImageGallery.data

  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="content">
        <div className="pt-32 lg:pt-36 mb-0 lg:mb-0">
          <div className="relative">
            <div className="grid">
              <div className="grid__full ">
                <div className="w-full type-lg lg:type-3xl text-white pb-10">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.gallery_title.html,
                    }}
                  />
                </div>
                <div className="w-full pb-8 rte">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.gallery_sub_text.html,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="grid__full pt-4 gallery">
                <SimpleReactLightbox>
                  <SRLWrapper options={lightboxOptions} callbacks={callbacks}>
                    {page.gallery_image.map((image, index) => {
                      console.log('here 2')
                      //console.log(image.gallery_image1.localFile)
                      if (
                        index < maxImages &&
                        image.gallery_image1.localFile !== null
                      ) {
                        return (
                          <>
                            <Img
                              key={index}
                              fluid={
                                image.gallery_image1.localFile.childImageSharp
                                  .fluid
                              }
                              alt={image.gallery_image1.alt}
                            />
                          </>
                        )
                      }
                    })}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
            <div className="my-10">
              {page.gallery_image.length >= maxImages && (
                <div className="grid text-center">
                  <div className="grid__full">
                    <button
                      className="hover:text-white rounded bg-yellow hover:bg-yellow-hover px-6 py-4 md:px-4 md:py-2 no-underline"
                      onClick={() => {
                        setMaxImages(maxImages + 6)
                      }}
                    >
                      {' '}
                      Show More
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

PhotoGalleryPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pagePhotoGalleryQuery = graphql`
  query {
    prismicImageGallery {
      data {
        meta_title
        meta_description
        canonical_url
        gallery_title {
          html
        }
        gallery_sub_text {
          html
        }
        gallery_image {
          gallery_image1 {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_title {
            text
          }
        }
      }
    }
  }
`
export default PhotoGalleryPage
